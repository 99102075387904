import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Location } from "@angular/common";

@Injectable()
export class Globals {
	public numAlb;
	public dataGestio;
	public nomProvC;
	public idAlb;
}