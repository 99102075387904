// Angular
// https://angular.io/
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

// Angular Material
// https://material.angular.io/
import {
	DateAdapter
} from "@angular/material/core";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';


import { MalihuScrollbarModule } from "ngx-malihu-scrollbar";
// ngx-bootstrap4
// http://valor-software.com/ngx-bootstrap/index-bs4.html#/
import { TabsModule } from "ngx-bootstrap/tabs";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { AlertModule } from "ngx-bootstrap/alert";
import { ModalModule } from "ngx-bootstrap/modal";
import { PopoverModule } from "ngx-bootstrap/popover";


// UI Shared Components
import { FooterComponent } from "../layout/footer/footer.component";
import { AppBackdropComponent } from "./components/app_backdrop/app_backdrop.component";

import {
	SmdFabSpeedDialActionsComponent,
	SmdFabSpeedDialComponent,
	SmdFabSpeedDialTriggerComponent
} from "./components/fab/index";
import { ImageCardComponent } from "app/shared/components/cards/image-card/image-card.component";
import { ImageOverCardComponent } from "app/shared/components/cards/image-over-card/image-over-card.component";
import { GalleryCardComponent } from "app/shared/components/cards/gallery-card/gallery-card.component";
import { ListCardComponent } from "app/shared/components/cards/list-card/list-card.component";
import { MailCardComponent } from "app/shared/components/cards/mail-card/mail-card.component";
import { ScheduleCardComponent } from "app/shared/components/cards/schedule-card/schedule-card.component";
import { SocialCardComponent } from "app/shared/components/cards/social-card/social-card.component";
import { ScrumCardComponent } from "app/shared/components/cards/scrum-card/scrum-card.component";
import { StackedCardsComponent } from "app/shared/components/cards/stacked-cards/stacked-cards.component";
import { TabsOverCardComponent } from "app/shared/components/cards/tabs-over-card/tabs-over-card.component";
import { WeatherCardComponent } from "app/shared/components/cards/weather-card/weather-card.component";
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { CustomDateAdapter } from "app/adapters/custom-data-adapter";
import { DeviceDetectorModule } from 'ngx-device-detector';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,		
		MatAutocompleteModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatCardModule,
		MatCheckboxModule,
		MatChipsModule,
		MatDatepickerModule,
		MatDialogModule,
		MatExpansionModule,
		MatGridListModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatTableModule,
		MatSelectModule,
		MatSidenavModule,
		MatSliderModule,
		MatSlideToggleModule,
		MatSnackBarModule,
		MatTabsModule,
		MatToolbarModule,
		MatTooltipModule,
		
		BsDropdownModule.forRoot(),
		AlertModule.forRoot(),
		TabsModule.forRoot(),
		MalihuScrollbarModule.forRoot(),
		ModalModule.forRoot(),
		PopoverModule.forRoot(),		
		DeviceDetectorModule
	],
	declarations: [
		AppBackdropComponent,
		FooterComponent,
		SmdFabSpeedDialActionsComponent,
		SmdFabSpeedDialComponent,
		SmdFabSpeedDialTriggerComponent,
		ImageCardComponent,
		ImageOverCardComponent,
		GalleryCardComponent,
		ListCardComponent,
		MailCardComponent,
		ScheduleCardComponent,
		ScrumCardComponent,
		SocialCardComponent,
		StackedCardsComponent,
		TabsOverCardComponent,
		WeatherCardComponent
	],
	exports: [
		CommonModule,
		FormsModule,
		
		AppBackdropComponent,
		ReactiveFormsModule,
		TabsModule,
		BsDropdownModule,
		AlertModule,
		MalihuScrollbarModule,
		ModalModule,
		PopoverModule,
		MatAutocompleteModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatCardModule,
		MatCheckboxModule,
		MatChipsModule,
		MatDatepickerModule,
		MatDialogModule,
		MatExpansionModule,
		MatGridListModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatTableModule,
		MatSelectModule,
		MatSidenavModule,
		MatSliderModule,
		MatSlideToggleModule,
		MatSnackBarModule,
		MatTabsModule,
		MatToolbarModule,
		MatTooltipModule,
		SmdFabSpeedDialActionsComponent,
		SmdFabSpeedDialComponent,
		SmdFabSpeedDialTriggerComponent,
		DeviceDetectorModule
	],
	providers:[
		{ provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
		{provide: DateAdapter, useClass: CustomDateAdapter }
	]
})
export class SharedModule {
	static forRoot(): ModuleWithProviders<SharedModule> {
		return {
			ngModule: SharedModule
		};
	}
}
