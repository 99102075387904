import { Injectable } from '@angular/core';
import { PublicService } from 'app/services/public.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseAuthService } from 'app/services/base-auth.service';
import { User, UserBasic } from 'app/services/models/user';
import { Observable } from 'rxjs';

@Injectable()
export class UserBaseService extends BaseAuthService {

  constructor(public publicService: PublicService, protected httpClient: HttpClient) {
    super(publicService);
  }

  protected getBasePath(): string {
    return super.getBasePath() + "User/";
  }
  protected getaBasePath(): string {
    return super.getBasePath();
  }

  public getUsers() {
    let url = this.getBasePath();
    return this.httpClient.get<User>(url, { headers: this.getHeaders() });
  }

  public getUsersRaw(): Observable<User> {
    let url = this.getBasePath() + "Raw/";
    return this.httpClient.get<User>(url, { headers: this.getHeaders() });
  }

  public getUser(id: string): Observable<UserBasic> {
    let url = this.getBasePath() + id;
    return this.httpClient.get<UserBasic>(url, { headers: this.getHeaders() });
  }

  public getAllEmails(dnis) {
    let url = this.getBasePath() + "GetEmails/"+dnis;
    console.log("getALLEMAILS");
    return this.httpClient.get<User[]>(url, { headers: this.getHeaders() });
  }

  public putUser(id: string, data) {
    let url = this.getBasePath() + id;
    return this.httpClient.put(url, data, { headers: this.getHeaders(), responseType: "text" });
  }

  public postUser(data) {
    let url = this.getBasePath();
    return this.httpClient.post(url, data, { headers: this.getHeaders(), responseType: "text" });
  }

  public sendPlanificationUser(body) {
    let url = this.getBasePath() + 'SendPlanificationUser';
    return this.httpClient.post(url, body, { headers: this.getHeaders(), responseType: 'text' });
  }

}





