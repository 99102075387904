import { Injectable, isDevMode } from '@angular/core';

export class BaseService {

    private _apiURL: string;

    constructor() {
        /*if (navigator.userAgent.indexOf("debugModeProMotor") >= 0) {
            this._apiURL = "https://lekmo.com/proves/wsProves/"; 
         } else {
            this._apiURL = "https://lekmo.com/G2Partes/WS/"; 
         }*/
         this._apiURL = "https://lekmo.com/G2LogisticTotNatura/WS/"
         //this._apiURL = "https://lekmo.com/proves/wsProves/"; 
    }

    protected getAPIURL(): string {
        return this._apiURL;
    }

    protected getBasePath(): string {
        return this.getAPIURL() + "index.php/";
    }

}
