import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { PagedData } from "../../../services/models/paged-data";
import { DataService } from "../../../services/data.service";
import { Dialogs } from "app/common/dialogs";
import { BaseComponentComponent } from "app/pages/base/base-component/base-component.component";
import { TranslateService } from "@ngx-translate/core";
import { PageProject } from 'app/services/models/page-project';


@Component({
  selector: 'app-base-datatable',
  templateUrl: './base-datatable.component.html',
  styleUrls: ['./base-datatable.component.scss']
})
export class BaseDatatableComponent<T> extends BaseComponentComponent implements OnInit {

  page = new PageProject();
  rows = new Array<T>();
  rowsAlb = new Array<T>();
  rows2 = new Array<T>();
  rowsArt = new Array<T>(); // filtre d'articles per afegir-lo al carrier (carriers-edit)

  selected = [];
  isToolbarActive: boolean = false;
  itemsSelected: string = "";
  itemCount: number = 0;
  allSelected: any;
  sortDir = "asc";
  ignoreCurrentUser = false;

  isSearchActive: boolean = false;
  searchValue: string = "";

  numItems = 0;

  public optionsCsv = {
    showLabels: true,
    showTitle: false,
  };

  public loadingInd = true;

  constructor(protected dataService: DataService<T>, protected dialog: Dialogs, public translate: TranslateService) {
    super();
    window.scrollTo(0, 0);
  }



  ngOnInit() {
    this.page.sortDir = this.getSortDir();
    this.page.sortColumn = this.getSortColumn();
    this.page.filter = "";
    this.page.ignoreCurrentUser = this.getignoreCurrentUser();

    this.setPage({ offset: 0 });
  }

  protected getMethodPath() {
    return "";
  }

  protected getLimit(): number {
    return 10;
  }

  protected getSortColumn(): string {
    return "";
  }

  protected getignoreCurrentUser() {
    return this.ignoreCurrentUser;
  }

  protected setTrueIgnoreCurrentUser() {
    this.ignoreCurrentUser = true;
  }

  protected getSortDir(): string {
    return this.sortDir;
  }

  protected setSortDir(dir) {
    this.sortDir = dir
  }

  public setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.page.size = this.getLimit();

    this.loadData();
  }


  protected loadData_albarans() {
    this.loadingInd = true;
    this.dataService.dataUpdated.subscribe(
        (data: PagedData<T>) => {
          this.loadingInd = false;
          this.page = data.page;         
          this.rowsAlb = data.miau.data;
        }
      );
      this.dataService.getResults("getAlbaransGest", this.page);

  }

  protected loadData_hours() {
    this.loadingInd = true;
      this.dataService.dataUpdated.subscribe(
        (data: PagedData<T>) => {
          //console.log(data);

        /*data.data.forEach(function(part, index, theArray) {
            let str:string[] = JSON.stringify(part).split(",");

            if(str[10] != undefined && str[10].length >10){
              let str2:string[] = str[10].split("T");
              if(str2[1] != undefined){
                str2[0] = str2[0] + "\"";
              }
              str[10] = str2[0];
            }
            var i = 0;
            var reconstructed = "";

            for(i=0; i<str.length-1; i++){
              reconstructed = reconstructed + str[i] + ","
            }
            reconstructed = reconstructed + str[str.length-1];
            var json = JSON.parse(reconstructed);
            theArray[index]=(json);
          });*/
        
          this.page = data.page;         
          this.rows2 = data.miau;
          this.loadingInd = false;
        }
      );
      this.dataService.getResults("Hour", this.page);
    }

    protected loadData_reg() {
      this.loadingInd = true;
      this.dataService.dataUpdated.subscribe(
        (data: PagedData<T>) => {
          //console.log(data);

        /*data.data.forEach(function(part, index, theArray) {
            let str:string[] = JSON.stringify(part).split(",");

            if(str[10] != undefined && str[10].length >10){
              let str2:string[] = str[10].split("T");
              if(str2[1] != undefined){
                str2[0] = str2[0] + "\"";
              }
              str[10] = str2[0];
            }
            var i = 0;
            var reconstructed = "";

            for(i=0; i<str.length-1; i++){
              reconstructed = reconstructed + str[i] + ","
            }
            reconstructed = reconstructed + str[str.length-1];
            var json = JSON.parse(reconstructed);
            theArray[index]=(json);
          });*/
        

          
          this.page = data.page;          
          this.rows2 = data.miau;
          this.loadingInd = false;
        }
      );
      this.dataService.getResults("totsRegs", this.page);
    }


  protected loadData_users() {
    this.dataService.dataUpdated.subscribe(
      (data: PagedData<T>) => {


      /*data.data.forEach(function(part, index, theArray) {
          let str:string[] = JSON.stringify(part).split(",");
          
          if(str[32] != undefined && str[32].length > 30){
            let str2:string[] = str[32].split("T");
            if(str2[1] != undefined) str2[0] = str2[0] + "\"";
            str[32] = str2[0];
          }

          if(str[33] != undefined && str[33].length > 30){
            let str2:string[] = str[33].split("T");
            if(str2[1] != undefined) str2[0] = str2[0] + "\"";
            str[33] = str2[0];
          }


          var i = 0;
          var reconstructed = "";

          for(i=0; i<str.length-1; i++){
            reconstructed = reconstructed + str[i] + ","
          }
          reconstructed = reconstructed + str[str.length-1];
          var json = JSON.parse(reconstructed);
          theArray[index]=(json);
        });*/
        
        this.page = data.page;
        this.rows2 = data.miau;
      }
    );
    this.dataService.getResults(this.getMethodPath(), this.page);
  }



  protected loadData_holidays() {
    this.dataService.dataUpdated.subscribe(
      (data: PagedData<T>) => {


      data.data.forEach(function(part, index, theArray) {

          let str:string[] = JSON.stringify(part).split(",");
          let str2:string[] = str[1].split("T");
          if(str2[1] != undefined){
            str2[0] = str2[0] + "\"";
          }

          str[1] = str2[0];
          var i = 0;
          var reconstructed = "";

          for(i=0; i<str.length-1; i++){
            reconstructed = reconstructed + str[i] + ","
          }
          reconstructed = reconstructed + str[str.length-1];
          var json = JSON.parse(reconstructed);
          theArray[index]=(json);

        });
      

        
        this.page = data.page;
        this.rows = data.data;
      }
    );

    this.dataService.getResults(this.getMethodPath(), this.page);
  }


  protected loadData_projects() {
    console.log("loadData_projects");
     this.dataService.dataUpdated.subscribe(
      (data: PagedData<T>) => {


      data.data.forEach(function(part, index, theArray) {


          let str:string[] = JSON.stringify(part).split(",");

          if(str[10] != undefined && str[10].length > 30){
            let str2:string[] = str[10].split("T");
            if(str2[1] != undefined) str2[0] = str2[0] + "\"";
            str[10] = str2[0];
          }

          if(str[11] != undefined && str[11].length > 30){
            let str2:string[] = str[11].split("T");
            if(str2[1] != undefined) str2[0] = str2[0] + "\"";
            str[11] = str2[0];
          }

          var reconstructed = "";

          for(var i=0; i<str.length-1; i++){
            reconstructed = reconstructed + str[i] + ","
          }
          reconstructed = reconstructed + str[str.length-1];
          
          var json = JSON.parse(reconstructed);
          theArray[index]=(json);

        });
        
        this.page = data.page;
        this.rows = data.data;
      }
    );


    this.dataService.getResults(this.getMethodPath(), this.page);
  }


  protected loadData() {

    this.dataService.dataUpdated.subscribe(
      (data: PagedData<T>) => {        
        this.page = data.page;
        this.rows = data.data;
      }
    );
    this.dataService.getResults(this.getMethodPath(), this.page);
  }

  //Sorting
  onSort(event) {
    this.page.sortDir = event.sorts[0].dir;
    this.page.sortColumn = event.sorts[0].prop;
    this.loadData();
  }

  //Filter data
  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    this.page.filter = val;
    this.loadData();
  }

  updateFilterId(id) {
    this.page.filter = id;
    this.loadData();
  }

  triggerClose(event) {
    this.page.filter = "";
    this.searchValue = "";

    this.isSearchActive = !this.isSearchActive;
    this.loadData();
  }

  triggerOpen() {
    this.isSearchActive = !this.isSearchActive;
    if (this.isSearchActive == true) {
      document.getElementById("filter").focus();
    }
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    if (selected.length == 1) {
      this.isToolbarActive = true;
      this.itemCount = selected.length;
      this.itemsSelected = "Item seleccionat";
    } else if (selected.length > 0) {
      this.isToolbarActive = true;
      this.itemCount = selected.length;
      this.itemsSelected = "Items seleccionats";
    } else {
      this.isToolbarActive = false;
    }
  }

  checkIfAllSelected() {
    if (this.rows.length == this.selected.length) {
      this.allSelected = true;
    } else {
      this.allSelected = false;
    }
  }

  protected deleteItem(path: string) {

    let deleteError = false;
    let self = this;

    //@todo deselccionar els items que s'acaben de borrar//
    this.numItems = 0;
    let elementsSelected = this.selected.length;

    for (var i = 0, len = this.selected.length; i < len; i++) {
      this.dataService.delete(path, this.selected[i].id).subscribe(
        data => {
          // this.dialog.showItemDeleted(this.getString("CORRECT_DELETE"), "").then(
          //   function () {
          //     self.loadData();
          //   }
          // );
          this.numItems++;


          if (this.numItems == elementsSelected) {
            this.dialog.showItemDeleted("S'han esborrat", "tots els elements seleccionats").then(
              function () {
                self.loadData();
              }
            );
          }
        },
        error => {
          this.dialog.showError("Error en esborrar", "No s'han pogut eliminar tots els elements seleccionats");
          self.isToolbarActive = false;
          this.deselectAllItems();

        },
        () => {
          self.isToolbarActive = false;
          this.deselectAllItems();
        }
      );
    }

  }

  showMessageDelete() {
    return this.dialog.showAskDelete(this.getString("DELETE"), this.getString("DELETE_MSG"));
  }

  deselectAllItems() {
    this.selected.splice(0, this.selected.length);
  }

  downloadData(fileName: string) {
    this.page.allData = true;
    this.dataService.dataUpdated.subscribe(
      (data: PagedData<T>) => {

        this.page = data.page;
        this.rows = data.data;
      }
    );

    this.dataService.getData(this.getMethodPath(), this.page.filter, this.page.filterUserId, this.page.filterDateStartFrom, this.page.filterDateStartTo, this.page.filterDateContractEnd,
      this.page.totalElements, 0, this.page.sortDir, this.page.sortColumn, this.page.ignoreCurrentUser, this.page.isActive, this.page.isPlanificable, this.page.isOcult, this.page.businessUnit, 
      this.page.filterNoRev, this.page.filterNoSync, this.page.filterFull, this.page.filterProv).subscribe(
      data => {
        var head = Object.keys(this.rows[0]);
        //new Angular2Csv(data.values, fileName, { headers: (head) });
        this.page.allData = false;
      },
      error => {
        this.page.allData = false;
      }
    )
    
  }

  protected loadData_artFilter(numAlb) {
      this.dataService.dataUpdated.subscribe(
        (data: PagedData<T>) => {
          this.page = data.page;
          this.rowsArt = data.miau;
          //console.log(this.rowsArt);
        }
      );
      this.page.filterFull = numAlb;
      this.dataService.getResults("getArtList", this.page);
    }

  protected loadData_artAllBarCode() {
      this.dataService.dataUpdated.subscribe(
        (data: PagedData<T>) => {
          this.page = data.page;
          this.rowsArt = data.miau;
          //console.log(this.rowsArt);
        }
      );
      this.dataService.getResults("getAllArts", this.page);
    }

}
