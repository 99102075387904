
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { BaseService } from "./base.service";
import { HttpParams } from "@angular/common/http";
//own
import { Session } from "./models/session.model";
import { CookieService } from 'ng2-cookies'
import { HttpHeaders } from "@angular/common/http";

const COOKIE_NAME_ACCESS_TOKEN: string = "accessToken";
const COOKIE_NAME_CLAM_ID: string = "claimId";
const COOKIE_NAME_AVATAR_HASH: string = "avatarHash";
const COOKIE_NAME_USER_NAME: string = "userName";
const COOKIE_NAME_NAME: string = "name";
const COOKIE_NAME_EMAIL: string = "eMail";
const COOKIE_NAME_IS_CONTRACT_ACTIVE: string = "isContractActive";
const COOKIE_NAME_IS_EXTRAS_AVIABLE: string = "isExtrasAvailable";
const COOKIE_CURRENT_USER_ID: string = "CurrentUserId";
const COOKIE_OPERATIU: string = "operatiu";
const COOKIE_NUMPARTE: string = "numParte";
const COOKIE_HOUR: string = "horari";
const COOKIE_TYPE_CAT: string = "tipusCat";
const COOKIE_ONLY_CONTROL: string = "onlyControl";
const COOKIE_PREU_ARTICLE: string = "preuArticle";
const COOKIE_DIES_LIMITE: string = "diesLimite";
const COOKIE_HORAS_PARTE: string = "horasParte";
const COOKIE_OPERARI: string = "operari";

const CLAIM_SUPER_ADMIN: string = "sa";
const CLAIM_ADMIN: string = "admin";
const CLAIM_STD: string = "std";

const TOKEN_TYPE: string = "PROMOTOR-AES-1 ";

@Injectable()
export class PublicService extends BaseService {

  constructor(private httpClient: HttpClient,
    private cookieService: CookieService) {
    super();
  }

  protected getBasePath(): string {
    return super.getBasePath() + "";
  }
    protected getahBasePath(): string {
    return super.getBasePath() + "getToken/";
  }
  protected getaiBasePath(): string {
    return super.getBasePath() + "sendRecoverMail/";
  }

  public _GetBasePath() {
    return super.getBasePath();
  }
  //methods user
  public login(email: string, password: string): Observable<Session> {
    //  console.log(this.getBasePath())
    var body = JSON.parse('{"user":"'+email+'","pass":"'+password+'"}');
    var url = this.getBasePath() + "login";
    //console.log("looooogiiiiin " + url);
    //console.log(body);
    //console.log(this.httpClient.post<Session>(url, body));
    return this.httpClient.post<Session>(url, body);
  }

  public CompleteResotrePassword(password: string, passwordRep: string, token: string) {

    var body = {
      newPassword: password,
      newPassword2: passwordRep,
      token: token,
    }

    var url = this.getBasePath() + "completeRestore/";
    return this.httpClient.post(url, body, { responseType: 'text' });
  }

  public CompleteRegister(password: string, passwordRep: string, token: string) {
    var body = {
      newPassword: password,
      newPassword2: passwordRep,
      token: token,
    }

    var url = this.getBasePath() + "CompleteRegister/";
    return this.httpClient.post(url, body, { responseType: 'text' });
  }

  public getToken(email){
    let url = this.getahBasePath();
    var body = JSON.parse('{"email":"'+email+'"}');
    return this.httpClient.post(url, body);
  }

    public resendPassword(email,token){
    let url= this.getaiBasePath();
    var body = JSON.parse('{"email":"'+email+'","token":"'+token+'"}');
    return this.httpClient.post(url, body);
  }

  public StartResotrePassword(email: string) {
    var url = this.getBasePath() + "RestorePassword/";
    let p: HttpParams = new HttpParams().set("email", email);
    let h: HttpHeaders = new HttpHeaders({ contentType: 'text' });

    return this.httpClient.get(url, { headers: h, params: p, responseType: 'text' });
  }

  public SendMassiveEmails(emails) {
    var url = this.getBasePath() + emails;
    //let p: HttpParams = new HttpParams().set("email", email);
    //let h: HttpHeaders = new HttpHeaders({ contentType: 'text' });

    return this.httpClient.get(url, { responseType: 'text' });
  }

  public getAvatar(avatarHash: string): string {
    return this.getBasePath() + "/GetAvatar?avatarHash=" + avatarHash;
  }

  //methods Cookies
  private removeAllCookies() {
    this.cookieService.deleteAll();
  }

  public logout() {
    this.removeAllCookies();
  }

  public setAccessToken(accessToken: string) {
    this.cookieService.set(COOKIE_NAME_ACCESS_TOKEN, accessToken);
  }

  public getAccessToken(): string {
    return this.cookieService.get(COOKIE_NAME_ACCESS_TOKEN);
  }

  public getTokenType(): string {
    return TOKEN_TYPE;
  }

  public setClaim(claim: string) {
    this.cookieService.set(COOKIE_NAME_CLAM_ID, claim);
  }

  public isStandard(): boolean {
    return this.getClamId() == CLAIM_STD ? true : false;
  }

  public isAdmin(): boolean {
    return this.getClamId() == CLAIM_ADMIN ? true : false;
  }

  public isSuperAdmin(): boolean {
    return this.getClamId() == CLAIM_SUPER_ADMIN ? true : false;
  }

  public getClamId(): string {
    return this.cookieService.get(COOKIE_NAME_CLAM_ID);
  }

  public setAvatarHash(hash: string) {
    this.cookieService.set(COOKIE_NAME_AVATAR_HASH, hash);
  }

  public getAvatarHash(): string {
    return this.cookieService.get(COOKIE_NAME_AVATAR_HASH);
  }

  public setUserName(username: string) {
    this.cookieService.set(COOKIE_NAME_USER_NAME, username);
  }

  public getUserName(): string {
    return this.cookieService.get(COOKIE_NAME_USER_NAME);
  }

  public setName(name: string) {
    this.cookieService.set(COOKIE_NAME_NAME, name);
  }

  public getName(): string {
    return this.cookieService.get(COOKIE_NAME_NAME);
  }

  public setOperatiu(operatiu: string) {
    this.cookieService.set(COOKIE_OPERATIU, operatiu);
  }

  public getOperatiu() {
    return this.cookieService.get(COOKIE_OPERATIU);
  }


  public setEMail(mail: string) {

    this.cookieService.set(COOKIE_NAME_EMAIL, mail);
  }

  public getEMail(): string {
    return this.cookieService.get(COOKIE_NAME_EMAIL);
  }

  public setIsContractActive(isContractActive: string) {
      
      this.cookieService.set(COOKIE_NAME_IS_CONTRACT_ACTIVE, isContractActive);
      //console.log(isContractActive)
  }

  public getIsContractActive(): string {
     // console.log("isActiveEntra");
      //console.log(COOKIE_NAME_IS_CONTRACT_ACTIVE);
    return this.cookieService.get(COOKIE_NAME_IS_CONTRACT_ACTIVE);
  }

  public setIsExtrasAvaiable(isExtrasAviable: string) {
    this.cookieService.set(COOKIE_NAME_IS_EXTRAS_AVIABLE, isExtrasAviable);
  }

  public getIsExtrasAvaiable(): string {
    return this.cookieService.get(COOKIE_NAME_IS_EXTRAS_AVIABLE);
  }

  public setCurrnetUserId(idCurrentUser: string) {
    this.cookieService.set(COOKIE_CURRENT_USER_ID, idCurrentUser);
  }

  public getCurrnetUserId():string {
    return this.cookieService.get(COOKIE_CURRENT_USER_ID);
  }

  //new

  public setDBName(name: string) {
    this.cookieService.set('myDBName', name);
  }

  public getDBName(): string {
    return this.cookieService.get('myDBName');
  }

  public setDBUser(user: string) {
    this.cookieService.set('myDBUser', user);
  }

  public getDBUser(): string {
    return this.cookieService.get('myDBUser');
  }

  public setDBPass(pass: string) {
    this.cookieService.set('myDBPass', pass);
  }

  public getDBPass(): string {
    return this.cookieService.get('myDBPass');
  }

  public setUserId(userid: string) {

    this.cookieService.set('userId', userid);
  }

  public getUserId(): string {
    return this.cookieService.get('userId');
  }

  public setUserDefaultTipus(tipusId: string) {
    this.cookieService.set('userTipus', tipusId);
  }

  public getUserDefaultTipus(): string {
    return this.cookieService.get('userTipus');
  }

  public setNumParte(numParte) {
    this.cookieService.set(COOKIE_NUMPARTE, numParte);
  }

  public getNumParte() {
    return this.cookieService.get(COOKIE_NUMPARTE);
  }

  public setHour(horari) {
    this.cookieService.set(COOKIE_HOUR, horari);
  }

  public getHour() { 
    return this.cookieService.get(COOKIE_HOUR);
  }


 public setTypeCat(tipusCat) { 
    this.cookieService.set(COOKIE_TYPE_CAT, tipusCat);
  }

  public getTypeCat() { 
    return this.cookieService.get(COOKIE_TYPE_CAT);
  }

  public setOnlyControl(onlyControl) { 
    this.cookieService.set(COOKIE_ONLY_CONTROL, onlyControl);
  }

  public getOnlyControl() { 
    return this.cookieService.get(COOKIE_ONLY_CONTROL);
  }

  public setPreuArticle(preuArticle) { 
    this.cookieService.set(COOKIE_PREU_ARTICLE, preuArticle);
  }

  public getPreuArticle() { 
    return this.cookieService.get(COOKIE_PREU_ARTICLE);
  }

  public setDiesLimite(diesLimite) { 
    this.cookieService.set(COOKIE_DIES_LIMITE, diesLimite);
  }

  public getDiesLimite() { 
    return this.cookieService.get(COOKIE_DIES_LIMITE);
  }

  public setHorasParte(horasParte) { 
    this.cookieService.set(COOKIE_HORAS_PARTE, horasParte);
  }

  public getHorasParte() { 
    return this.cookieService.get(COOKIE_HORAS_PARTE);
  }

  public setOperari(preuConcept) { 
    this.cookieService.set(COOKIE_OPERARI, preuConcept);
  }

  public getOperari() { 
    return this.cookieService.get(COOKIE_OPERARI);
  }

  //new

  public isAuthenticated(): Promise<any> {
    return new Promise(
      (resolve, reject) => {
        resolve(this.getAccessToken() != null && this.getAccessToken() != "")
      }
    );
  }

}
